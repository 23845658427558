import { VFC, useRef } from 'react';
import * as Icon from 'react-feather';

import { Select } from './atoms/Select';
import { useSearch } from '../hooks/useSearch';
import { ConditionBox } from './ConditionBox';

export const SearchForm: VFC = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const {
    selectedInfoType,
    searchConditionList,
    isSearching,
    setInfoType,
    createNewCondition,
    submit,
  } = useSearch();

  return (
    <form
      ref={formRef}
      className="bg-darkblue_10 text-darkblue_100 p-5 rounded h-fit
      max-xl:min-h-[220px] max-xl:max-h-[440px] max-xl:overflow-scroll max-xl:overflow-x-hidden"
    >
      <p>
        <Select
          name="infotype"
          value={selectedInfoType}
          onChange={(e) => setInfoType(e.target.value)}
          className="py-2.5"
          required
        >
          <option value="jian">事案</option>
          <option value="dispatchInfo">出場</option>
          <option value="patientInfo">傷病者</option>
        </Select>
        <span className="ml-4">から以下の条件で検索</span>
      </p>
      <div className="pt-4 space-y-2">
        {searchConditionList.map((condition) => (
          <ConditionBox key={condition.id} id={condition.id} infoType={selectedInfoType} />
        ))}
      </div>
      <div className="flex justify-center">
        <button
          type="button"
          className="bg-white text-darkblue_100 font-bold rounded py-1 px-4 mt-2 border border-darkblue_100"
          onClick={() => createNewCondition()}
        >
          検索条件を追加
        </button>
      </div>
      <div className="flex justify-end">
        <button
          type="submit"
          className="flex justify-center items-center gap-2 bg-darkblue_100 text-white font-bold text-xl rounded py-2 px-3 mt-2"
          onClick={(e) => {
            e.preventDefault();
            if (!formRef.current?.reportValidity()) {
              return; // required属性がある項目に入力がない場合、submitをキャンセル
            }
            submit();
          }}
          disabled={isSearching}
        >
          <Icon.Search size={24} />
          検索
        </button>
      </div>
    </form>
  );
};
