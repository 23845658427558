import { useCallback } from 'react';
import { selector, useRecoilValue } from 'recoil';
import { toast } from 'react-hot-toast';

import { getAuthToken } from '../../utils/getAuthToken';
import { sleep } from '../../utils/sleep';
import { useRegion } from '../useRegion';

// -----------------------------------------------------------------------------
// Util Functions
// -----------------------------------------------------------------------------
const API_URL = process.env.REACT_APP_PDF_OUTPUT_API_ENDPOINT;

const getBaseUrl = (): string | null => {
  if (API_URL === undefined) {
    return null;
  }
  return API_URL;
};

// -----------------------------------------------------------------------------
// CRUD Operation Functions
// -----------------------------------------------------------------------------
type Template = {
  templateId: string;
  templateName: string;
};

const getTemplates = async (): Promise<Template[]> => {
  const baseUrl = getBaseUrl();
  const token = await getAuthToken();

  if (baseUrl === null || !token) {
    return [];
  }

  const response = await fetch(`${baseUrl}/templates`, {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to get templates (responce is not ok)');
  }

  const body = await response.json();
  const templates = body?.templates;

  if (!Array.isArray(templates)) {
    throw new Error(
      'Failed to get templates (responce body do not have `templates` property as array)'
    );
  }

  return templates;
};

const postForOutputId = async (templateId: string, jianId: string): Promise<string> => {
  const baseUrl = getBaseUrl();
  const token = await getAuthToken();

  if (baseUrl === null || !token) {
    return '';
  }

  const response = await fetch(`${baseUrl}/outputs`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify({
      templateId,
      jianId,
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to post outputs');
  }

  const body = await response.json();

  if (body == null) {
    throw new Error('body is null or undefined');
  }

  if (typeof body.outputId !== 'string') {
    throw new Error('outputId is not string');
  }

  return body.outputId;
};

const getDownloadUrl = async (
  outputId: string,
  retry: number = 60,
  interval: number = 3000
): Promise<string> => {
  for (let i = 0; i < retry; i++) {
    await sleep(interval);

    const baseUrl = getBaseUrl();
    const token = await getAuthToken();

    const response = await fetch(`${baseUrl}/status/${outputId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to get download url (responce is not ok)');
    }

    const result = await response.json();
    if (result.status === 'failed') {
      throw new Error('Failed to get download url (status is failed)');
    }

    if (result.status === 'generated' && typeof result.url === 'string' && result.url !== '') {
      return result.url;
    }
  }

  throw new Error('Failed to get download url (timeout)');
};

// -----------------------------------------------------------------------------
// Recoil States
// -----------------------------------------------------------------------------
const pdfTemplatesSelector = selector<Template[]>({
  key: 'usePdfDownload/pdfTemplates',
  get: async () => {
    const templates = await getTemplates();
    return templates;
  },
});

// -----------------------------------------------------------------------------
// Hooks
// -----------------------------------------------------------------------------
export const usePdfTemplates = () => {
  return useRecoilValue(pdfTemplatesSelector);
};

export const usePdfDownload = (jianId: string) => {
  const region = useRegion();
  return useCallback(
    async (templateId: string): Promise<void> => {
      if (region === 'Default') {
        const toastId = toast.loading('帳票連携を処理中...');
        try {
          await postForOutputId(templateId, jianId);
          toast.success('帳票連携に成功しました', { id: toastId });
        } catch (e) {
          toast.error('帳票連携に失敗しました', { id: toastId });
          console.error(e);
        }
      }
      if (region === 'Fujisawa') {
        const toastId = toast.loading('PDFをダウンロード中...');
        try {
          const outputId = await postForOutputId(templateId, jianId);
          const downloadUrl = await getDownloadUrl(outputId);
          window.location.href = downloadUrl;
          toast.success('ダウンロードが完了しました', { id: toastId });
        } catch (e) {
          toast.error('ダウンロードに失敗しました', { id: toastId });
          console.error(e);
        }
      }
      return;
    },
    [jianId, region]
  );
};
