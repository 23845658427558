import React from 'react';
import { Popover } from '@headlessui/react';
import * as Icon from 'react-feather';

import { components } from '../../schema/hospital-management';
import { useSetFields } from '../../hooks/useJianDetail';
import { HospitalSearch } from '../../components/HospitalSearch/HospitalSearch';

export type HospitalConverter = (hospital: components['schemas']['Hospital']) => {
  fieldId: string;
  value: string;
}[];

export const HospitalSearchButton: React.VFC<{
  convertHospitalToFields: HospitalConverter;
}> = ({ convertHospitalToFields: convertHospitalInfo }) => {
  const setField = useSetFields();

  return (
    <Popover className="relative">
      <Popover.Button className="ml-1 p-1 rounded bg-gray-400">
        <Icon.Search size={20} className="text-white" />
      </Popover.Button>
      <Popover.Panel className="absolute z-10 -right-12 lg:-right-48 md:-right-44">
        {({ close }) => (
          <HospitalSearch
            onSelectHospital={(hospital) => {
              const fields = convertHospitalInfo(hospital);
              for (const field of fields) {
                setField(field.fieldId, field.value);
              }
              close();
            }}
          />
        )}
      </Popover.Panel>
    </Popover>
  );
};
