// 病院管理機能のロジックを担うHooks

import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { toast } from 'react-hot-toast';

import { hospitalApiClient as client } from '../utils/apiClient';
import { components } from '../schema/hospital-management';
import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export type HospitalSearchParams = {
  hospitalName?: string | null;
  organizationCode?: string | null;
  ownCode?: string | null;
  conveyorCode?: string | null;
  favoriteOnly?: boolean | null;
};

// -----------------------------------------------------------------------------
// CRUD Operation Functions
// -----------------------------------------------------------------------------
const getHospitalList = async ({
  hospitalSearchParams,
  limit,
  nextToken,
}: {
  hospitalSearchParams: HospitalSearchParams;
  limit: number;
  nextToken?: string;
}) => {
  const res = await client.GET('/hospitals', {
    params: {
      query: {
        ...hospitalSearchParams,
        limit,
        nextToken,
      },
    },
  });
  if (res.error) {
    throw new Error(`Failed to GET /hospitals \n message: ${res.error.message}`);
  }
  return res.data;
};

const getHospital = async (hospitalId: string) => {
  const res = await client.GET(`/hospitals/{hospitalId}`, {
    params: {
      path: { hospitalId },
    },
  });
  if (res.error) {
    throw new Error(`Failed to GET /hospitals/${hospitalId} \n message: ${res.error.message}`);
  }
  return res.data;
};

const updateHospital = async ({
  hospitalId,
  body,
}: {
  hospitalId: string;
  body: components['schemas']['HospitalUpdateRequest'];
}) => {
  const res = await client.PATCH(`/hospitals/{hospitalId}`, {
    params: { path: { hospitalId } },
    body,
  });
  if (res.error) {
    throw new Error(`Failed to PATCH /hospitals/{hospitalId} \n message: ${res.error.message}`);
  }
  return res.data;
};

// -----------------------------------------------------------------------------
// Recoil States
// -----------------------------------------------------------------------------
// 現在のページ
const currentPageState = atom({
  key: 'hospitalListCurrentPageState',
  default: 0,
});

// 1ページあたりの表示件数
const limitState = atom({
  key: 'hospitalListLimitState',
  default: 10,
});

// -----------------------------------------------------------------------------
// Hooks
// -----------------------------------------------------------------------------
// 検索条件
export const useHospitalSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const refreshHospitalList = useRefreshHospitalList();
  const currentSearchParams = Array.from(searchParams.entries());

  const hospitalSearchParams: HospitalSearchParams = {
    hospitalName: searchParams.get('hospitalName'),
    organizationCode: searchParams.get('organizationCode'),
    ownCode: searchParams.get('ownCode'),
    conveyorCode: searchParams.get('conveyorCode'),
    favoriteOnly: searchParams.get('favoriteOnly') === 'true' ? true : undefined,
  };

  const setHospitalSearchParams = useCallback(
    (params: HospitalSearchParams) => {
      const newParams = new URLSearchParams();

      // HospitalSearchParams 以外の既存の検索条件を引き継ぐ
      if (currentSearchParams.length > 0) {
        currentSearchParams.forEach(([key, value]) => {
          if (
            key !== 'hospitalName' &&
            key !== 'organizationCode' &&
            key !== 'ownCode' &&
            key !== 'conveyorCode' &&
            key !== 'favoriteOnly'
          ) {
            newParams.set(key, value);
          }
        });
      }

      if (params.hospitalName) newParams.set('hospitalName', params.hospitalName);
      if (params.organizationCode) newParams.set('organizationCode', params.organizationCode);
      if (params.ownCode) newParams.set('ownCode', params.ownCode);
      if (params.conveyorCode) newParams.set('conveyorCode', params.conveyorCode);
      if (params.favoriteOnly) newParams.set('favoriteOnly', 'true');
      setSearchParams(newParams);
      refreshHospitalList();
    },
    [setSearchParams, refreshHospitalList, currentSearchParams]
  );

  return { hospitalSearchParams, setHospitalSearchParams };
};

// 病院一覧を取得する
export const useHospitalList = () => {
  const [page, setPage] = useRecoilState(currentPageState);
  const limit = useRecoilValue(limitState);
  const { hospitalSearchParams } = useHospitalSearchParams();
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery<components['schemas']['Hospitals']>(
    {
      queryKey: ['hospitals', hospitalSearchParams],
      queryFn: async ({ pageParam = undefined }) => {
        return await getHospitalList({ hospitalSearchParams, limit, nextToken: pageParam });
      },
      getNextPageParam: (lastPage) => lastPage.nextToken,
    }
  );
  const isLatestPage = data?.pages.length === page + 1;
  const isLastPage = isLatestPage && !hasNextPage;

  const incrementPage = useCallback(() => {
    if (isLastPage) return;
    if (isLatestPage) fetchNextPage();
    setPage(page + 1);
  }, [setPage, fetchNextPage, page, isLastPage, isLatestPage]);

  const decrementPage = useCallback(() => {
    setPage(page > 0 ? page - 1 : 0);
  }, [setPage, page]);

  return {
    hospitalList: data?.pages.at(page)?.hospitals ?? [],
    page,
    limit,
    isLastPage,
    incrementPage,
    decrementPage,
  };
};

// 病院詳細を取得する
export const useHospital = (hospitalId: string | undefined) => {
  const { data } = useQuery({
    queryKey: ['hospital', hospitalId],
    queryFn: async () => {
      return await getHospital(hospitalId ?? '');
    },
    enabled: !!hospitalId,
  });

  return data;
};

// 病院を更新する
export const useUpdateHospital = () => {
  const queryClient = useQueryClient();
  const setPage = useSetRecoilState(currentPageState);
  const mutation = useMutation({
    mutationFn: async ({
      hospitalId,
      ...body
    }: { hospitalId: string } & components['schemas']['HospitalUpdateRequest']) => {
      const promise = updateHospital({ hospitalId, body });
      toast.promise(promise, {
        loading: '病院情報を更新しています...',
        success: '病院情報を更新しました',
        error: '病院情報の更新に失敗しました',
      });
      const res = await promise;
      queryClient.resetQueries(['hospital', hospitalId]);
      queryClient.resetQueries(['hospitals']);
      setPage(0);
      return res;
    },
  });

  return mutation.mutateAsync;
};

// 病院一覧をRefreshする
export const useRefreshHospitalList = () => {
  const queryClient = useQueryClient();
  const setPage = useSetRecoilState(currentPageState);
  return () => {
    queryClient.resetQueries(['hospitals']);
    setPage(0);
  };
};
