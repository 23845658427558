import React, { useState } from 'react';

import { TeamItemEditCard } from './TeamItemEditCard';
import { EditButton, DeleteButton, ViewText } from '../Components';
import { useDeleteTeam } from '../../../hooks/useTeams';

export const TeamItem = ({
  teamId,
  teamName,
  teamCode,
  isEditable,
  isDeletable,
}: {
  teamId: string;
  teamName: string;
  teamCode: string;
  isEditable: boolean;
  isDeletable: boolean;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const { isDeleting, deleteTeam } = useDeleteTeam();

  if (isEditing) {
    return (
      <TeamItemEditCard
        initValues={{ teamId, teamName, teamCode }}
        onSubmit={() => setIsEditing(false)}
        onCancel={() => setIsEditing(false)}
      />
    );
  }

  return (
    <li className="w-full flex justify-between items-center p-4 border-b border-darkblue_70 max-xl:flex-col max-xl:items-end max-xl:gap-2">
      <div className="flex gap-2 max-xl:w-full">
        <ViewText label="隊名" value={teamName} />
        <ViewText label="隊コード" value={teamCode} />
      </div>
      <div className="w-fit flex items-center gap-2">
        {isDeletable && (
          <DeleteButton
            disabled={isDeleting}
            onClick={() => {
              if (window.confirm(`${teamName}のデータを削除しますか？`)) {
                deleteTeam(teamId);
              }
            }}
          />
        )}
        {isEditable && <EditButton disabled={isDeleting} onClick={() => setIsEditing(true)} />}
      </div>
    </li>
  );
};
