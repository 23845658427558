import React, { useState } from 'react';

import { CategoryList } from './CategoryList';
import { FieldList } from './FieldList';

type Props = {
  pattern: string[];
  onToggleField: (fieldId: string) => void;
};

export const PatternEditor: React.VFC<Props> = ({ pattern, onToggleField }) => {
  const [selectedCategory, setSelectedCategory] = useState<string>('出動概要');
  return (
    <div className="flex h-full">
      <div className="w-112 h-full border-r-2 border-darkblue_100 overflow-y-auto max-xl:w-fit">
        <CategoryList
          selectedCategory={selectedCategory}
          setSelectedCategory={(category: string) => setSelectedCategory(category)}
        />
      </div>
      <div className="grow w-80 overflow-y-auto">
        <FieldList
          pattern={pattern}
          selectedCategory={selectedCategory}
          onToggleField={onToggleField}
        />
      </div>
    </div>
  );
};
