import React from 'react';
import { useRegion } from '../../../hooks/useRegion';

export const TableHeader: React.VFC = () => {
  const region = useRegion();
  return (
    <thead className="sticky top-0 bg-white">
      <tr className="border-b-2 border-darkblue_100 flex flex-nowrap">
        <th className="flex-none text-lg w-48 text-left p-4">{/* インポートボタン用スペース */}</th>
        <th className="flex-none text-lg w-60 text-left p-4">覚知時刻</th>
        <th className="flex-auto text-lg w-48 text-left p-4">隊名</th>
        {region === 'Default' && <th className="flex-none text-lg w-48 text-left p-4">事案番号</th>}
        <th className="flex-none text-lg w-20 text-left p-4">年齢</th>
        <th className="flex-none text-lg w-20 text-left p-4">性別</th>
        <th className="flex-none text-lg w-52 text-left p-4">氏名</th>
        <th className="flex-none text-lg w-56 text-left p-4">事案Id</th>
      </tr>
    </thead>
  );
};
