import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Navigation } from './Navigation';
import { MoveToJianListButton } from '../../components/MoveToJianListButton';

export const MasterSetting: React.VFC = () => {
  return (
    <div className="w-full h-full px-24">
      <MoveToJianListButton />
      <h1 className="text-2xl font-bold text-darkblue_100 mb-4">設定</h1>
      <Suspense fallback={<div>Loading...</div>}>
        <div className="h-3/4 flex gap-4 max-xl:flex-col">
          <Navigation />
          <Outlet />
        </div>
      </Suspense>
    </div>
  );
};
