import React from 'react';
import { Popover } from '@headlessui/react';
import { DownloadCloud, Check, Slash, ChevronDown } from 'react-feather';

import {
  useAppJians,
  useImportAppJian,
  useImportAppJianAsPatient,
} from '../../../hooks/useAppJian';
import { formatJapaneseDateTimeString } from '../../../utils/dateUtils';
import { useRegion } from '../../../hooks/useRegion';

export const TableBody = () => {
  const { appJians } = useAppJians();
  const { setSelectedAppJian } = useImportAppJianAsPatient();
  const importAppJian = useImportAppJian();
  const region = useRegion();

  return (
    <>
      <tbody>
        {appJians.map((jian) => (
          <tr
            key={jian.nserMobileDataId}
            className="w-full border-b last:border-0 border-darkblue_100 flex flex-nowrap items-center"
          >
            <td className="flex-none text-lg w-48 text-left p-2">
              {jian.importStatus === 'IMPORTABLE' ? (
                <ImportButton
                  onSelect={() =>
                    importAppJian(jian.nserMobileDataId, jian.awarenessDatetime ?? '')
                  }
                  onSelectAsPatientInfo={() => setSelectedAppJian(jian.nserMobileDataId)}
                />
              ) : jian.importStatus === 'IMPORTED' ? (
                <ImportedButton />
              ) : jian.importStatus === 'NOT_ALLOWED' || jian.importStatus === 'NOT_IMPORTABLE' ? (
                <NotImportableButton />
              ) : null}
            </td>
            <td className="flex-none text-lg w-60 text-left p-4">
              {jian.awarenessDatetime != null && !Number.isNaN(Date.parse(jian.awarenessDatetime))
                ? formatJapaneseDateTimeString(new Date(jian.awarenessDatetime), 'second')
                : '---'}
            </td>
            <td className="flex-auto text-lg w-48 p-4 break-words">{jian.teamName}</td>
            {region === 'Default' && (
              <td className="flex-none text-lg w-48 text-left p-4 break-words">
                {jian.jianNumber}
              </td>
            )}
            <td className="flex-none text-lg w-20 text-left p-4">{jian.patientAge}</td>
            <td className="flex-none text-lg w-20 text-left p-4">
              {jian.patientSex === 'MALE'
                ? '男'
                : jian.patientSex === 'FEMALE'
                ? '女'
                : jian.patientSex === 'NOTKNOWN'
                ? '不明'
                : null}
            </td>
            <td className="flex-none text-lg w-52 text-left p-4 break-words">{jian.patientName}</td>
            <td className="flex-none text-sm w-56 text-left p-4 break-words">{jian.jianId}</td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

// インポートボタン
const ImportButton: React.VFC<{
  onSelect: () => void;
  onSelectAsPatientInfo: () => void;
  disabled?: boolean;
}> = ({ onSelect, onSelectAsPatientInfo, disabled }) => {
  return (
    <div
      className={`flex w-fit  rounded-md border-2 ${
        disabled
          ? 'bg-gray-100 border-gray-400 text-gray-500'
          : 'bg-darkblue_10 border-darkblue_100 text-darkblue_100'
      } relative`}
    >
      <button
        onClick={onSelect}
        disabled={disabled}
        className="flex gap-2 items-center px-2.5 py-1"
      >
        <DownloadCloud size={24} />
        <div className="text-sm font-bold">インポート</div>
      </button>
      <Popover className="relative">
        <Popover.Button
          disabled={disabled}
          className={`flex items-center px-1.5 h-full border-l ${
            disabled ? 'border-gray-300' : 'border-darkblue_50'
          }`}
        >
          <ChevronDown size={16} />
        </Popover.Button>
        <Popover.Panel className="z-10 absolute right-0 py-1 rounded bg-darkblue_10 border border-darkblue_70">
          <Popover.Button
            className="hover:bg-darkblue_20 px-2 py-1 min-w-max text-sm"
            onClick={onSelectAsPatientInfo}
          >
            傷病者として追加
          </Popover.Button>
        </Popover.Panel>
      </Popover>
    </div>
  );
};

// インポート済みボタン
const ImportedButton = () => {
  return (
    <button
      disabled
      className="flex gap-2 items-center bg-gray-100 rounded-md border-2 border-gray-400 px-2 py-1"
    >
      <Check size={24} className="text-gray-500" />
      <div className="text-sm text-gray-500 font-bold">インポート済み</div>
    </button>
  );
};

// インポート不可ボタン
const NotImportableButton = () => {
  return (
    <button
      disabled
      className="flex gap-2 items-center bg-gray-100 rounded-md border-2 border-gray-400 px-2 py-1"
    >
      <Slash size={24} className="text-gray-500" />
      <div className="text-sm text-gray-500 font-bold">インポート不可</div>
    </button>
  );
};
