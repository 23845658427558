import { Route, Routes } from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';

import { NotFound } from './components/NotFound';
import { JianList } from './Pages/JianList/JianList';
import { JianDetail } from './Pages/JianDetail';
import { JianSearch } from './Pages/JianSearch/JianSearch';
import { MasterSetting, MemberMaster, TeamMaster, RoleMaster } from './Pages/MasterSetting';
import { AppJian } from './Pages/AppJian/AppJian';
import { CsvPattern } from './Pages/CsvPattern/CsvPattern';
import { CsvPatternList } from './Pages/CsvPattern/CsvPatternList/CsvPatternList';
import { CsvPatternDetail } from './Pages/CsvPattern/CsvPatternDetail/CsvPatternDetail';
import { Hospital } from './Pages/Hospital/Hospital';
import { HospitalList } from './Pages/Hospital/HospitalList/HospitalList';
import { HospitalDetail } from './Pages/Hospital/HospitalDetail/HospitalDetail';
import { HeaderComponent } from './components/HeaderComponent';

const JianListWrapper = () => {
  return <JianList />;
};

export const AuthenticatedRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HeaderComponent />}>
        <Route index element={<JianListWrapper />} />
        <Route path=":year">
          <Route index element={<JianListWrapper />} />
          <Route path="jian/:jianId/:infoType" element={<JianDetail />}>
            <Route index element={<JianDetail />} />
            <Route path=":infoTypeId" element={<JianDetail />} />
          </Route>
          <Route path="csv-pattern" element={<CsvPattern />}>
            <Route index element={<CsvPatternList />} />
            <Route path=":patternId" element={<CsvPatternDetail />} />
          </Route>
          <Route path="search" element={<JianSearch />} />
          <Route path="app-jians" element={<AppJian />} />
        </Route>
        <Route path="hospital" element={<Hospital />}>
          <Route index element={<HospitalList />} />
          <Route path=":hospitalId" element={<HospitalDetail />} />
        </Route>
        <Route path="setting" element={<MasterSetting />}>
          <Route path="team" element={<TeamMaster />} />
          <Route path="member" element={<MemberMaster />} />
          <Route path="role" element={<RoleMaster />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};
