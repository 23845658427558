import React from 'react';
import { useRegion } from '../../../../hooks/useRegion';

export const TableHeader: React.VFC = () => {
  const region = useRegion();
  return (
    <thead className="sticky top-0 bg-white">
      <tr className="border-b-2 border-darkblue_100 flex flex-nowrap">
        <th className="text-lg w-20 text-left p-4">{/* 詳細ボタン用スペース */}</th>
        <th className="text-lg w-8 text-left p-4">{/* お気に入りアイコン用スペース */}</th>
        <th className="text-lg w-48 text-left p-4">医療機関名</th>
        {region === 'Fujisawa' && <th className="text-lg w-40 text-left p-4">国コード</th>}
        <th className="text-lg w-44 text-left p-4">医療機関コード</th>
        <th className="text-lg w-40 text-left p-4">設立別</th>
        <th className="text-lg w-40 text-left p-4">管内・外</th>
        <th className="text-lg w-48 text-left p-4">病院分類</th>
      </tr>
    </thead>
  );
};
