import { useCallback } from 'react';
import { toast } from 'react-hot-toast';

import { useMe } from '../useMe';
import { sleep } from '../../utils/sleep';
import { datacrudApiClient as client } from '../../utils/apiClient';

// -----------------------------------------------------------------------------
// CRUD Operation Functions
// -----------------------------------------------------------------------------

const postForRequestId = async (
  fireDepartmentId: string,
  jianIds: string[],
  fieldIds: string[]
): Promise<string> => {
  const { data, error } = await client.POST('/selective-csv/csv/{fireDepartmentId}', {
    params: {
      path: { fireDepartmentId },
    },
    body: {
      jianIds,
      targetFieldIds: fieldIds,
    },
  });

  if (error) {
    throw new Error(
      `Failed to POST /selective-csv/csv/${fireDepartmentId} \n message: ${error.message}`
    );
  }

  if (data.requestId === '') {
    throw new Error('Failed to get request_id');
  }

  return data.requestId;
};

const getDownloadUrl = async (
  fireDepartmentId: string,
  requestId: string,
  retry: number,
  interval_ms: number
): Promise<string> => {
  for (let i = 0; i < retry; i++) {
    await sleep(interval_ms);

    const { data, error } = await client.GET('/selective-csv/csv/{fireDepartmentId}/{requestId}', {
      params: {
        path: {
          fireDepartmentId,
          requestId,
        },
      },
    });

    if (error) {
      console.error(error);
      throw new Error(`Failed to GET /selective-csv/csv/${fireDepartmentId}/${requestId}`);
    }

    if (data.status === 'COMPLETED' && data.url !== '') {
      return data.url;
    }
  }

  throw new Error('Failed to get download url (timeout)');
};

// -----------------------------------------------------------------------------
// Hooks
// -----------------------------------------------------------------------------
export const useCsvSelectedDownload = () => {
  const me = useMe();
  return useCallback(
    async (jianIds: string[], fieldIds: string[]) => {
      const fireDepartmentId = me?.fireDepartmentId;

      if (fireDepartmentId == null) {
        toast.error('認証情報が取得できませんでした。ログインし直してください。');
        console.error('Failed to get fireDepartmentId');
        return;
      }

      const toastId = toast.loading('ダウンロード中...');
      try {
        const requestId = await postForRequestId(fireDepartmentId, jianIds, fieldIds);
        const downloadUrl = await getDownloadUrl(fireDepartmentId, requestId, 90, 10 * 1000); // 10秒間隔90回リトライ
        toast.success('ダウンロードが完了しました', { id: toastId });
        window.location.href = downloadUrl;
      } catch (e) {
        toast.error('ダウンロードに失敗しました', { id: toastId });
        console.error(e);
      }
    },
    [me]
  );
};
