import React, { useState } from 'react';
import { Switch } from '@headlessui/react';
import { Star, StarBorder } from '@mui/icons-material';
import { useParams } from 'react-router-dom';

import { useHospital, useUpdateHospital } from '../../../hooks/useHospital';
import { ActionPanel } from './ActionPanel';
import { MoveToHospitalListButton } from './MoveToHospitalListButton';
import {
  decodeEmbodimentCode,
  decodeEstablishmentCode,
  decodeEmergencyCode,
} from '../../../utils/hospitalCode';
import { components } from '../../../schema/hospital-management';
import { useRegion } from '../../../hooks/useRegion';

export const HospitalDetail: React.VFC = () => {
  const { hospitalId } = useParams();
  const hospital = useHospital(hospitalId);
  const updateHospital = useUpdateHospital();
  const [hospitalInputs, setHospitalInputs] = useState<
    Partial<components['schemas']['HospitalUpdateRequest']>
  >({});
  const region = useRegion();

  if (!hospital) {
    throw new Error('Hospital not found');
  }
  const isEditing =
    (hospitalInputs.ownCode !== undefined && hospitalInputs.ownCode !== hospital.ownCode) ||
    (hospitalInputs.jurisdiction !== undefined &&
      hospitalInputs.jurisdiction !== hospital.jurisdiction) ||
    (hospitalInputs.favorite !== undefined && hospitalInputs.favorite !== hospital.favorite);

  return (
    <div className="w-full h-full space-y-2">
      <MoveToHospitalListButton />
      <ActionPanel
        hospitalName={hospital.hospitalName}
        isEditing={isEditing}
        onSubmit={async () => {
          await updateHospital({
            hospitalId: hospital.hospitalId,
            ownCode: hospitalInputs?.ownCode ?? hospital.ownCode,
            jurisdiction: hospitalInputs?.jurisdiction ?? hospital.jurisdiction,
            favorite: hospitalInputs?.favorite ?? hospital.favorite,
          });
          setHospitalInputs({});
        }}
      />
      <div className="p-4 space-y-4">
        <FavoriteSwitch
          checked={hospitalInputs?.favorite ?? hospital.favorite}
          onChange={(value) => setHospitalInputs((prev) => ({ ...prev, favorite: value }))}
        />
        {region === 'Fujisawa' && (
          <label className="flex items-center gap-3 min-w-fit">
            <div className="text-sm text-darkblue_70 font-bold">医療機関コード</div>
            <input
              type="text"
              value={hospitalInputs.ownCode}
              onChange={(e) => setHospitalInputs((prev) => ({ ...prev, ownCode: e.target.value }))}
              className="w-60 rounded border border-darkblue_70 px-2 py-1 text-darkblue_100"
            />
          </label>
        )}
        <label className="flex items-center gap-3 min-w-fit">
          <div className="text-sm text-darkblue_70 font-bold">管内・外</div>
          <select
            value={hospitalInputs.jurisdiction}
            onChange={(e) => {
              setHospitalInputs((prev) => ({
                ...prev,
                jurisdiction: e.target.value as 'INSIDE' | 'OUTSIDE',
              }));
            }}
            className="w-32 rounded border border-darkblue_70 px-2 py-1 text-darkblue_100"
          >
            <option value="INSIDE">管内</option>
            <option value="OUTSIDE">管外</option>
          </select>
        </label>
        {region === 'Default' && (
          <DisabledInputText label="医療機関コード" value={hospital.conveyorCode} />
        )}
        {region === 'Fujisawa' && (
          <DisabledInputText label="国コード" value={hospital.organizationCode} />
        )}
        <DisabledInputText
          label="設立別"
          value={decodeEmbodimentCode(hospital.hospitalEmbodimentType)}
        />
        <DisabledInputText
          label="病院分類"
          value={decodeEstablishmentCode(hospital.hospitalEstablishmentType)}
        />
        <DisabledInputText label="搬送機関コード" value={hospital.conveyorCode} />
        <DisabledInputText
          label="病院分類"
          value={decodeEmergencyCode(hospital.hospitalEmergencyType)}
        />
      </div>
    </div>
  );
};

const DisabledInputText: React.VFC<{ label: string; value: string }> = ({ label, value }) => (
  <label className="flex items-center gap-3 min-w-fit">
    <div className="text-sm text-darkblue_70 font-bold">{label}</div>
    <input type="text" disabled value={value} className="border-0 px-2 py-1 text-darkblue_100" />
  </label>
);

const FavoriteSwitch: React.VFC<{ checked: boolean; onChange: (value: boolean) => void }> = ({
  checked,
  onChange,
}) => (
  <Switch
    checked={checked}
    onChange={onChange}
    className={`px-2 py-1 flex items-center gap-2 rounded border-2 ${
      checked
        ? 'text-darkblue_100 border-darkblue_100 bg-darkblue_10'
        : 'text-darkblue_40 border-darkblue_40'
    }`}
  >
    {checked ? <Star /> : <StarBorder />}
    <div className="font-bold">お気に入り</div>
  </Switch>
);
