import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';
import { Loading } from './components/Loading';
import { signIn as amplifySignIn, signOut as amplifySignOut } from 'aws-amplify/auth';
import { useQueryClient } from '@tanstack/react-query';
import { useRefreshAppJians } from './hooks/useAppJian';
import { useRefreshHospitalList } from './hooks/useHospital';
import { useRefreshJianList } from './hooks/useJianList';

interface AuthContextType {
  isAuthenticated: boolean | null;
  signIn: (username: string, password: string) => Promise<boolean | void>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const queryQlient = useQueryClient();
  const refreshJianList = useRefreshJianList();
  const refreshAppJian = useRefreshAppJians();
  const refreshHospitals = useRefreshHospitalList();

  const checkAuthStatus = async () => {
    try {
      await getCurrentUser();
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
    }
  };

  const signIn = async (username: string, password: string) => {
    try {
      const { isSignedIn } = await amplifySignIn({ username, password });
      if (isSignedIn) {
        setIsAuthenticated(true);
      }
    } catch (error) {
      setIsAuthenticated(false);
      throw error;
    }
  };

  const signOut = async () => {
    await amplifySignOut();
    queryQlient.clear();
    refreshJianList();
    refreshAppJian();
    refreshHospitals();
    setIsAuthenticated(false);
  };

  useEffect(() => {
    checkAuthStatus();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, signIn, signOut }}>
      {isAuthenticated === null ? <Loading /> : children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
