import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthProvider';
import { Loading } from '../../components/Loading';
import { Button, TextField, Flex, View, Alert } from '@aws-amplify/ui-react';

export const SignInForm = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { isAuthenticated, signIn } = useAuth();

  useEffect(() => {
    if (isAuthenticated === true) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const handleSignIn = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);
    setLoading(true);

    try {
      await signIn(username, password);
      navigate('/');
    } catch (error) {
      setError('ユーザー名またはパスワードが間違っています');
    } finally {
      setLoading(false);
    }
  };

  if (isAuthenticated === null) {
    return <Loading />;
  }

  return (
    <Flex justifyContent="center" alignItems="center" height="100vh" className="bg-darkblue_10">
      <View
        as="form"
        onSubmit={handleSignIn}
        className="w-full max-w-md p-8 space-y-4 bg-white rounded-lg shadow-md"
      >
        <h2 className="text-2xl font-bold text-center">サインイン</h2>
        <TextField
          label="ユーザー名"
          value={username}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
          required
          placeholder="ユーザー名を入力してください"
        />
        <TextField
          label="パスワード"
          type="password"
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
          required
          placeholder="パスワードを入力してください"
        />
        {error && <Alert variation="error">{error}</Alert>}
        <Button
          type="submit"
          isLoading={loading}
          loadingText="サインイン中…"
          variation="primary"
          isFullWidth
        >
          サインイン
        </Button>
      </View>
    </Flex>
  );
};
