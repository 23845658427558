import { Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';

import { AuthProvider } from './AuthProvider';
import { SignInForm } from './Pages/SignInForm/SignInForm';
import { AuthenticatedRoutes } from './AuthenticatedRoutes';
import { PrivateRoute } from './PrivateRoute';
import { Loading } from './components/Loading';
import { Helmet } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/signin" element={<SignInForm />} />
      <Route
        path="/*"
        element={
          <PrivateRoute>
            <AuthenticatedRoutes />
          </PrivateRoute>
        }
      />
    </Route>
  )
);

export const App = () => {
  return (
    <AuthProvider>
      <Helmet>
        <title>NOA | Login</title>
      </Helmet>
      <Toaster />

      <Suspense fallback={<Loading />}>
        <RouterProvider router={router} />
      </Suspense>
    </AuthProvider>
  );
};
