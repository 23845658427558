import { getRegion } from '../../hooks/useRegion';

// 覚知時刻のfieldIdを取得
export const getAwarenessDateTimeFieldId = () => {
  const region = getRegion();
  switch (region) {
    case 'Fujisawa':
      return '9a56ebd8-60b3-4999-b5cb-521073cb8d3f';
    case 'Default':
      return 'c52159d0-24dd-4736-aa0b-af50b27d3499';
  }
};
