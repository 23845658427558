import { Suspense, VFC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { useJianSelection } from './hooks/useJianSelection';
import { SelectedJianController } from './SelectedJianController/SelectedJianController';
import { JianListHeader } from './JianListHeader';
import { JianListItem } from './JianListItem';
import { ErrorFallBack } from '../ErrorFallBack';
import { components } from '../../schema/data-crud';
import { getAwarenessDateTimeFieldId } from './utils';

type JianListTableProps = {
  jians: JianTableItem[];
  className?: string;
};

type JianTableItem = {
  jianId: string;
  infoType: 'dispatch-info' | 'patient-info';
  infoTypeId: string;
  fields: {
    fieldId: string;
    fieldName: string;
    fieldType: components['schemas']['FieldType'];
    value?: components['schemas']['Value'];
  }[];
};

export const JianListTable: VFC<JianListTableProps> = ({ jians, className }) => {
  const jianIds = jians.map((jian) => jian.jianId);
  const templateFileds =
    jians.at(0)?.fields.map(({ fieldId, fieldName }) => ({ fieldId, fieldName })) ?? [];
  const { selectedJianIds, isSelected, areAllSelected, selectJianId, deselectJianId } =
    useJianSelection();

  const isHeaderCheckboxChecked = areAllSelected(jianIds);
  const onChangeHeaderCheckbox = (value: boolean) => {
    if (value) {
      selectJianId(jianIds);
    } else {
      deselectJianId(jianIds);
    }
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div>
        <SelectedJianController selectedJianIds={selectedJianIds} />
        <div className={`relative border-collapse overflow-scroll h-full w-full ${className}`}>
          <table>
            <JianListHeader
              fieldNames={templateFileds
                .filter((field) => field.fieldId !== getAwarenessDateTimeFieldId())
                .map((field) => field.fieldName)}
              checked={isHeaderCheckboxChecked}
              onChange={onChangeHeaderCheckbox}
            />
            <tbody>
              {jians?.map((jian) => (
                <ErrorBoundary key={jian.jianId} FallbackComponent={ErrorFallBack}>
                  <Suspense
                    fallback={
                      <tr>
                        <td>Loading...</td>
                      </tr>
                    }
                  >
                    <JianListItem
                      jian={jian}
                      isSelected={isSelected}
                      selectJianId={selectJianId}
                      deselectJianId={deselectJianId}
                    />
                  </Suspense>
                </ErrorBoundary>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Suspense>
  );
};
